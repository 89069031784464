import axios from "@/axios"

export default {
    signUp(payload) {
        return axios.post("/client/auth/signup", payload)
    },
    signIn(payload) {
        return axios.post("/client/auth/signin", payload)
    },
    verifyAccount(payload) {
        return axios.post("/client/auth/verify-account", payload)
    },
    fetchUser() {
        return axios.get("/client/auth/user")
    },
    requestPasswordReset(email) {
        return axios.post("/client/auth/request-password-reset", { email: email })
    },
    resetPassword(payload) {
        return axios.post("/client/auth/reset-password", payload)
    },
    updateAvailability(status) {
        return axios.patch("/client/dashboard/availability", { status: status })
    }
}