import axios from "@/axios"

export default {
    fetchProjects() {
        return axios.get("/client/projects")
    },
    fetchProject(id) {
        return axios.get(`/client/projects/${id}`)
    },
    fetchProjectEmployees(id) {
        return axios.get(`/client/projects/${id}/talents`)
    },
    createProject(payload) {
        return axios.post("/client/projects", payload)
    }
}