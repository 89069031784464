import state from './moduleVacationState.js'
import mutations from './moduleVacationMutations.js'
import actions from './moduleVacationActions.js'
import getters from './moduleVacationGetters.js'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}