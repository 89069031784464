import standup from "../../http/requests/standup";

export default {
    SET_STAND_UPS(state, standups) {
        state.standups = standups;
    },

    GET_STAND_UP(state, standup) {
        state.standup = standup;
    },
}