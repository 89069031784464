import settings from "@/http/requests/settings";

export default {
    updateProfile({ commit }, payload) {
        return new Promise((resolve, reject) => {
            settings.updateProfile(payload)
                .then(response => {
                    commit("UPDATE_USER_INFO", response.data.data, { root: true })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateProfilePicture({ commit }, payload) {
        return new Promise((resolve, reject) => {
            settings.updateProfilePicture(payload)
                .then(response => {
                    commit("UPDATE_USER_INFO", response.data.data, { root: true })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}