import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VuePhoneNumberInput from "vue-phone-number-input";
import vSelect from "vue-select";
import VueToast from 'vue-toast-notification';
import titleMixin from './mixins/titleMixin'
import axios from  './axios';
import  "./filters.js"
import store from "./store";
import Loading from "vue-loading-overlay";
import Dayjs from 'vue-dayjs';

Vue.prototype.$http = axios

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(FontAwesomeIcon);
Vue.use(VuePhoneNumberInput);
Vue.mixin(titleMixin);
Vue.use(vSelect);
Vue.use(VueToast, {
  position: "top-right",
});
Vue.use(Loading);
Vue.use(Dayjs);

// Import stylesheet
import "@fortawesome/fontawesome-free/css/all.min.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import 'vue-toast-notification/dist/theme-sugar.css';
import "animate.css";
import "./assets/scss/app.scss";

const accessToken = localStorage.getItem("accessToken");

if (accessToken) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
      "Bearer " + accessToken;
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");