import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard"
  },
  {
    path: "/",
    component: () => import("./layouts/LayoutAuth"),
    children: [
      {
        name: "register",
        path: "/register",
        component: () => import("./views/Auth/Register"),
        meta: {
          rule: 'public'
        }
      },
      {
        name: "verify-account",
        path: "/verify-account",
        component: () => import("./views/Auth/VerifyAccount"),
        meta: {
          rule: 'public'
        }
      },
      {
        name: "login",
        path: "/login",
        component: () => import("./views/Auth/Login"),
        meta: {
          rule: 'public'
        }
      },
      {
        name: "forgot-password",
        path: "/forgot-password",
        component: () => import("./views/Auth/ForgotPassword"),
        meta: {
          rule: 'public'
        }
      },
      {
        name: "reset-password",
        path: "/reset-password",
        component: () => import("./views/Auth/ResetPassword"),
        meta: {
          rule: 'public'
        }
      },
    ],
  },
  {
    path: "/dashboard",
    component: () => import("./layouts/LayoutMain"),
    children: [
      {
        path: "",
        component: () => import("./views/Dashboard"),
        meta: {
          title: "Dashboard",
          description: "Overview of your daily activities",
          rule: "private"
        },
      },
      {
        path: "projects",
        component: () => import("./views/Projects"),
        meta: {
          title: "Projects",
          description: "Overview of your projects",
          rule: "private"
        },
      },
      {
        path: "projects/:id",
        component: () => import("./views/Projects/Details.vue"),
        meta: {
          title: "Project Details",
          description: "",
          rule: "private"
        },
      },
      {
        path: "stand-up",
        component: () => import("./views/StandUp"),
        meta: {
          title: "Stand Up",
          description: "Overview of your daily activities",
          rule: "private"
        },
      },
      {
        path: "employees",
        component: () => import("./views/Dashboard/Employees"),
        meta: {
          title: "Employees",
          description: "Overview of employees",
          rule: "private",
          titleFromType: true
        },
      },
      {
        path: "employees/:id",
        component: () => import("./views/Dashboard/EmployeeDetails.vue"),
        meta: {
          title: "Employees",
          description: "Overview of employees",
          rule: "private",
          titleFromType: true
        },
      },
      {
        path: "settings",
        component: () => import("./views/Settings"),
        meta: {
          title: "Settings",
          description: "",
          rule: "private"
        },
      },
      {
        path: "settings/payment",
        component: () => import("./views/Settings/Payment"),
        meta: {
          title: "Settings",
          description: "",
          rule: "private"
        },
      },
      {
        name: "billing",
        path: "billing",
        component: () => import("./views/Billing"),
        meta: {
          title: "Billing",
          description: "Overview of users requests",
          rule: "private"
        },
      },
      {
        name: "billing-overview",
        path: "billing/:id",
        component: () => import("./views/Billing/Overview"),
        meta: {
          title: "Billing",
          description: "Overview of users billing",
          rule: "private"
        }
      },
      {
        name: "vacation",
        path: "vacation",
        component: () => import("./views/Vacation"),
        meta: {
          title: "Vacation",
          description: "Overview of employers on leave",
          rule: "private"
        },
      },
      {
        path: "support",
        component: () => import("./views/Support"),
        meta: {
          title: "Support",
          description: "Need help? We’re ready to answer your question 24/7",
          rule: "private"
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.rule !== "public" && !store.getters["auth/isUserLoggedIn"]) {
    next("/login");
  } else if (to.name === "login" && store.getters["auth/isUserLoggeedIn"]) {
    next("/dashboard");
  } else next();
});

export default router;
