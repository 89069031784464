import standup from "@/http/requests/standup";

export default {
    fetchStandUp({ commit }) {
        return new Promise((resolve, reject) => {
          standup
            .fetchStandup()
            .then((response) => {
              commit("SET_STAND_UPS", response.data.data, { root: true });
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
    },

    getStandUp({ commit }, id) {
        return new Promise((resolve, reject) => {
          standup.getStandUp(id)
            .then((response) => {
              commit("GET_STAND_UP", response.data.data)
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

}