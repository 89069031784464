import axios from "@/axios"

export default {
    fetchVacations() {
        return axios.get("/client/vacations")
    },
    getVacation(id) {
        return axios.get(`/client/vacations/${id}`)
    },
    approveVacation(id, payload) {
        return axios.patch(`/client/vacations/${id}/approve`, payload)
    },
    declineVacation(id, payload) {
        return axios.patch(`/client/vacations/${id}/reject`, payload)
    },
}