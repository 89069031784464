import axios from "@/axios"

export default {
    fetchEmployees() {
        return axios.get("/client/talents")
    },
    fetchEmployee(id) {
        return axios.get(`/client/talents/${id}`)
    },
    requestTalent(payload) {
        return axios.post("/client/talent_requests", payload)
    },
}