import state from './moduleSettingsState.js'
import mutations from './moduleSettingsMutations.js'
import actions from './moduleSettingsActions.js'
import getters from './moduleSettingsGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
