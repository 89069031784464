import vacation from "../../http/requests/vacation";

export default {
    SET_VACATIONS(state, vacations) {
        state.vacations = vacations;
    },

    GET_VACATION(state, vacation) {
        state.vacation = vacation;
    },
    APPROVE_VACATION(state, payload) {
        state.vacations = state.vacations.map(vacation => {
          if (vacation.id === payload.id) {
            return Object.assign({}, vacation, payload.data)
          }
          return vacation
        })
    },
    DECLINE_VACATION(state, payload) {
        state.vacations = state.vacations.map(vacation => {
          if (vacation.id === payload.id) {
            return Object.assign({}, vacation, payload.data)
          }
          return vacation
        })
    },
}