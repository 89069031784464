import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

import moduleAuth from "./auth/moduleAuth.js";
import moduleSettings from "./settings/moduleSettings.js";
import moduleStandup from "./standup/moduleStandup";
import moduleProjects from "./projects/moduleProjects.js";
import moduleVacations from "./vacations/moduleVacation";
import moduleEmployees from "./employees/moduleEmployees";

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        auth: moduleAuth,
        settings: moduleSettings,
        standup: moduleStandup,
        projects: moduleProjects,
        vacations: moduleVacations,
        employees: moduleEmployees

    },
    strict: process.env.NODE_ENV !== "production",
});
