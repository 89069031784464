import employees from "@/http/requests/employees"

export default {
    fetchEmployees({ commit }) {
        return new Promise((resolve, reject) => {
            employees.fetchEmployees()
                .then(response => {
                    commit("FETCH_EMPLOYEES", response.data.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    fetchEmployee({ commit }, id) {
        return new Promise((resolve, reject) => {
            employees.fetchEmployee(id)
                .then(response => {
                    commit("FETCH_EMPLOYEE", response.data.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    requestTalent({ commit }, payload) {
        return new Promise((resolve, reject) => {
            employees.requestTalent(payload)
                .then(response => {
                    commit("REQUEST_TALENT", response.data.data, { root: true })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}