import auth from "@/http/requests/auth"

export default {
    signUp({}, payload) {
        return new Promise((resolve, reject) => {
            auth
                .signUp(payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    verifyAccount({}, payload) {
        return new Promise((resolve, reject) => {
            auth
                .verifyAccount(payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    signIn({ commit }, payload) {
        return new Promise((resolve, reject) => {
            auth.signIn(payload)
                .then(response => {
                    commit("SET_BEARER", response.data.data.token.access_token)
                    commit("SET_REMEMBER_ME", payload.rememberMe)
                    commit("UPDATE_USER_INFO", response.data.data.user, { root: true })
                    resolve(response)

                })
                .catch(error => { reject(error) })
        })
    },
    signOut({ commit }) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userInfo')
        commit("SIGN_OUT")
    },
    fetchUser({ commit }) {
        return new Promise((resolve, reject) => {
            auth.fetchUser()
                .then(response => {
                    commit("UPDATE_USER_INFO", response.data.data, { root: true })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateAvailability({ commit }, status) {
        return new Promise((resolve, reject) => {
            auth.updateAvailability(status)
                .then(response => {
                    commit("UPDATE_USER_INFO", response.data.data, { root: true })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    requestPasswordReset({}, email) {
        return new Promise((resolve, reject) => {
            auth.requestPasswordReset(email)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetPassword({ }, payload) {
        return new Promise((resolve, reject) => {
            auth.resetPassword(payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}