import vacation from "@/http/requests/vacation";

export default {
    fetchVacations({ commit }) {
        return new Promise((resolve, reject) => {
          vacation
            .fetchVacations()
            .then((response) => {
              commit("SET_VACATIONS", response.data.data, { root: true });
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
    },

    getVacation({ commit }, id) {
        return new Promise((resolve, reject) => {
          vacation.getVacation(id)
            .then((response) => {
              commit("GET_VACATION", response.data.data)
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
      approveVacation({ commit }, payload) {
        return new Promise((resolve, reject) => {
          vacation.approveVacation(payload.id, payload.data)
            .then(response => {
              commit("APPROVE_VACATION", payload, response.data.data, { root: true })
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      declineVacation({ commit }, payload) {
        return new Promise((resolve, reject) => {
          vacation.declineVacation(payload.id, payload.data)
            .then(response => {
              commit("DECLINE_VACATION", payload, response.data.data, { root: true })
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      },

}