import Vue from "vue";
import axios from 'axios';
import router from "./router";
import store from "./store"


const UNVALIDATED_ROUTES = [
  'login', 'forgot-password', 'reset-password'
]

const signOut = () => {
  store.dispatch('auth/signOut')
  router.push('/login')
}
let baseURL = "https://staging-talent-backend.menaget.net/api/v1"

const Axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || baseURL
});


// const disableToastAPIs = ['API one', 'API to'];
Axios.interceptors.response.use((response) => {
  console.log(response, 'waiting')
  

  // if (!disableToastAPIs.includes(response.config.url)) {
  //   // Show toast
  // }
  if (response.status == 200 || response.status == 201) {
    localStorage.setItem('timestamp', Date.now())
    if (response?.data?.message) {
      Vue.$toast.success(response.data.message)
    }
  }
  return response
}, (error) => {
  const data = error?.response?.data
  if (error?.response?.status === 401) {
    signOut()
  }
  else if (error?.response?.status === 403) {
    Vue.$toast.error(data.message)
  }
  else if (error?.response?.status === 422) {
    Vue.$toast.warning(data.message)
    if (!UNVALIDATED_ROUTES.includes(router.currentRoute.name)) {
      Object.keys(data.errors).forEach(key => {
        data.errors[key].forEach(err => {
          Vue.$toast.error(err)
        })
      })
    }
  }
  else if (error?.response?.status === 429) {
    Vue.$toast.warning(data.message)
  }
  else if (error?.response?.status === 500) {
    if (data.message === "Unauthenticated.") {
      signOut()
    }
  }
  return Promise.reject(error)
})


export default Axios;