import projects from "@/http/requests/projects"

export default {
    fetchProjects({ commit }) {
        return new Promise((resolve, reject) => {
            projects.fetchProjects()
                .then(response => {
                    commit("FETCH_PROJECTS", response.data.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    fetchProject({ commit }, id) {
        return new Promise((resolve, reject) => {
            projects.fetchProject(id)
                .then(response => {
                    commit("FETCH_PROJECT", response.data.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    fetchProjectEmployees({ commit }, id) {
        return new Promise((resolve, reject) => {
            projects.fetchProjectEmployees(id)
                .then(response => {
                    commit("FETCH_PROJECT_EMPLOYEES", response.data.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    createProject({ commit }, payload) {
        return new Promise((resolve, reject) => {
          projects
            .createProject(payload)
            .then((response) => {
                commit("CREATE_PROJECT", response.data.data, { root: true })
                resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
}