import axios from "@/axios"

export default {
  SET_BEARER(state, accessToken) {
    state.accessToken = accessToken
    localStorage.setItem('accessToken', accessToken)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
  },
  SET_REMEMBER_ME(state, rememberMe) {
     state.rememberMe = rememberMe
     localStorage.setItem('rememberMe', rememberMe)
  },
  SIGN_OUT(state) {
      state.accessToken = null
  }
}